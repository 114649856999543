import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import getLanguage from '../utils/getLanguage';

export default function Invitation() {
  useEffect(() => {
    const $inviteCarousel = $('#inviteCarousel');
    $inviteCarousel.carousel('pause');
    $('.thumbLink').on('click', function (e) {
      $inviteCarousel.carousel(Number($(this).data('link')));
      e.preventDefault();
    });

    $(window).keydown(function (e) {
      if (e.keyCode == 37) {
        $inviteCarousel.carousel('prev');
      } else if (e.keyCode == 39) {
        $inviteCarousel.carousel('next');
      }
    });
  });

  const language = getLanguage();

  return (
    <Layout page="invitation">
      <SEO title="Invitation" />
      <div className="textContent">
        <h2>Wedding Invitation</h2>
        {language === 'en' ? (
          <>
            <p>
              We designed the wedding invitation by ourselves using the gray-pink color theme from
              <a href="http://www.colourlovers.com/palette/109872/he_still_loves_her">
                colourlovers.com
              </a>
              We think it represents our characteristics: sweet and modern. The card has two designs
              for the front side and one design for the back side. Please click on the thumbnails or
              use arrow keys to view the invitation. Hope to see you all on the 7th.
            </p>
            <p>P.S. If possible, please dress according to the gray-pink theme to the party.</p>
          </>
        ) : (
          <>
            <p>
              เราทำการ์ดง่ายๆออกแบบกันเอง เน้น
              <a href="http://www.colourlovers.com/palette/109872/he_still_loves_her">
                โทนสีชมพูเทา
              </a>
              ซึ่งเป็นโทนสีของงาน และยังบ่งบอก character ของเจ้าบ่าวเจ้าสาวอีกด้วย
              การ์ดมีด้านหน้าสองแบบและด้านหลังหนึ่งแบบ
              กรุณากดปุ่มลูกศรหรือกดที่รูปเล็กเพื่อเลือกดูรูปใหญ่ได้เลยค่ะ ทุกรูปสามารถคลิกขวาเพื่อ
              save ได้ทันที แล้วเจอกันวันที่ 7 นะคะ
            </p>
            <p>ป.ล. ถ้าแต่งกายมางานด้วยโทนสีชมพูเทาได้ด้วยจะเยี่ยมเลยครับ</p>
          </>
        )}
      </div>
      <div className="text-align-center">
        <div className="inline center">
          <a className="thumbLink" data-link="0" href="">
            <img className="inviteThumbnail" src="/img/invitation/bm_invite1.png" />
          </a>
          <a className="thumbLink" data-link="1" href="#inviteCarousel">
            <img className="inviteThumbnail" src="/img/invitation/bm_invite2.jpg" />
          </a>
          <a className="thumbLink" data-link="2" href="#inviteCarousel">
            <img className="inviteThumbnail" src="/img/invitation/bm_invite3.png" />
          </a>
        </div>
      </div>
      <p>&nbsp;</p>
      <div className="carousel slide" id="inviteCarousel">
        {/* <!-- Carousel items --> */}
        <div className="carousel-inner">
          <div className="item active">
            <img src="/img/invitation/bm_invite1.png" />
          </div>
          <div className="item">
            <img src="/img/invitation/bm_invite2.jpg" />
          </div>
          <div className="item">
            <img src="/img/invitation/bm_invite3.png" />
          </div>
        </div>
        {/* <!-- Carousel nav --> */}
        <a className="carousel-control left" data-slide="prev" href="#inviteCarousel">
          &lsaquo;
        </a>
        <a className="carousel-control right" data-slide="next" href="#inviteCarousel">
          &rsaquo;
        </a>
      </div>
      <p>&nbsp;</p>
    </Layout>
  );
}
